<template>
  <div class="gps-cars">
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
    ></view-grid>
    <component
      @formEvent="formEvent"
      @saveEvent="saveEvent"
      @bindEvent="bindEvent"
      ref="refAnyCom"
      :formArray="formArray"
      :is="currentCopmonent"
      :row="curr_row"
      :dialogTitle="t('users.Title')"
      :groupArray="groupData"
    />
  </div>
</template>

<script setup>
import ViewGrid from "./components/ViewGrid.vue";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  nextTick,
  // markRaw,
  // toRef,
} from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import cars from "./UsersData";
import { ElNotification } from "element-plus";
import { ElMessage } from "element-plus";
const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();

//动态组件
const activeKey = ref("GpsForm");
let currentCopmonent = shallowRef({});
currentCopmonent.value = defineAsyncComponent(() =>
  import(`./components/${activeKey.value}.vue`)
);
watch(
  () => activeKey.value,
  () => {
    currentCopmonent.value = defineAsyncComponent(() =>
      import(`./components/${activeKey.value}.vue`)
    );
  }
);

//表格显示的列
const tableCols = ref(cars.tableCols);
//表格操作列
const tableOps = ref(cars.tableOps);
//表单操作
const formArray = reactive(cars.formCols);
const row = {};
const curr_row = reactive(row);
const searchData = ref([]);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = ref(cars.searchCols);

const tableData = ref([]);
const query = reactive({ rp: 25, page: 1 });

const getList = () => {
  proxy.$api.sys_users_grid(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
  });
  // proxy.$api.item_get_company({}).then((res) => {
  //   let ay = res.data.data.map((it) => {
  //     it.name = it.cName;
  //     it.value = it.id;
  //     return it;
  //   });
  //   formArray[2].data = ay;
  // });
};
const groupData = ref([]);
const init = () => {
  proxy.$api
    .sys_get_bases({ flag: ["TF", "NCLOSE", "UserType"] })
    .then((res) => {
      formArray[4].data = res.data.UserType;
      formArray[8].data = res.data.NCLOSE;
      formArray[10].data = res.data.TF;
      formArray[12].data = res.data.TF;
      formArray[13].data = res.data.TF;

      // formArray[8].data = res.data.sblx;
    });
  proxy.$api.tree_get_company_tree({}).then((res) => {
    groupData.value = res.data.data;
  });
  // proxy.$api.sys_role_select({}).then((res) => {
  //   let ay = [];
  //   if (res.data.data.length > 0) {
  //     res.data.data.forEach((it) => {
  //       ay.push({
  //         value: it.id,
  //         name: it.rName,
  //       });
  //     });
  //   }
  //   formArray[5].data = ay;
  // });
  // let ay_channel = [];
  // for (let i = 0; i < 17; i++) {
  //   ay_channel.push({
  //     name: i.toString(),
  //     value: i.toString(),
  //   });
  // }
  // formArray[10].data = ay_channel;
  // //查询初始化
  proxy.$api.sys_init_datas({}).then((res) => {
    if (res.data.companys.length > 0) {
      let ay_companys = res.data.companys.map((it) => {
        return {
          name: it.cName,
          value: it.id,
        };
      });
      // console.log(searchCols);
      formArray[0].data = ay_companys;
    }
  });
};

onMounted(() => {
  getList();
  init();
});

let cpy_id = 0;
let user_tid = 0;
let vel_ids = [];

let velIds = "";

const setBindInfo = () => {
  if (cpy_id > 0 && user_tid > 0) {
    switch (parseInt(user_tid)) {
      case 2:
        formArray[14].type = "empty";
        formArray[14].label = "";
        formArray[14].field = "";
        formArray[14].placeholder = "";
        formArray[14].val = "";
        formArray[14].required = false;
        formArray[14].data = [];
        break;
      case 3:
        proxy.$api.tree_get_company_tree_by_id({ cid: cpy_id }).then((res) => {
          formArray[14].type = "group_ipts";
          formArray[14].label = t("users.bdcz");
          formArray[14].field = "uVelIDS";
          formArray[14].placeholder = t("users.bdcz");
          formArray[14].val = vel_ids;
          formArray[14].required = false;
          formArray[14].data = res.data.data;
        });
        // proxy.$api.sys_get_vehgroup_all({ cid: cpy_id }).then((res) => {
        //   formArray[14].type = "group_ipts";
        //   formArray[14].label = t("users.bdcz");
        //   formArray[14].field = "uVelIDS";
        //   formArray[14].placeholder = t("users.bdcz");
        //   formArray[14].val = vel_ids;
        //   formArray[14].required = false;
        //   formArray[14].data = res.data.data;
        // });
        break;
      case 4:
        proxy.$api
          .sys_get_vehicles_all({
            zt: 1,
            is_bind: 1,
            cid: cpy_id,
            ids: velIds,
            company_id: cpy_id,
          })
          .then((res) => {
            // console.log(res.data.data);
            res.data.data.forEach((it) => {
              it.label = it.vPlate;
            });
            formArray[14].type = "veh_ipts";
            formArray[14].label = t("users.bdcl");
            formArray[14].field = "uVelIDS";
            formArray[14].placeholder = t("users.bdcl");
            formArray[14].val = vel_ids;
            formArray[14].required = false;
            formArray[14].data = res.data.data;
          });

        break;
    }
  } else {
    formArray[14].type = "empty";
    formArray[14].label = "";
    formArray[14].field = "";
    formArray[14].placeholder = "";
    formArray[14].val = "";
    formArray[14].required = false;
    formArray[14].data = [];
  }
};

const formEvent = (obj) => {
  switch (obj.type) {
    case "company":
      cpy_id = obj.value;
      user_tid = obj.tid;
      curr_row.uCompanyID = parseInt(cpy_id);
      formArray[0].val = cpy_id;
      // console.log("company", curr_row);
      if (obj.velids) velIds = obj.velids;
      // console.log("user_tid", user_tid);
      // if (obj.velids) {
      //   vel_ids = obj.velids;
      //   console.log("vel_ids", vel_ids);
      // }
      setBindInfo();
      // console.log("init", cpy_id);
      if (cpy_id) {
        proxy.$api.sys_role_select({ cid: cpy_id }).then((res) => {
          let ay = [];
          if (res.data.data.length > 0) {
            res.data.data.forEach((it) => {
              ay.push({
                value: it.id,
                name: it.rName,
              });
            });
          }
          formArray[5].data = ay;
        });
      }

      // proxy.$api.sys_get_vehgroup_all({ cid: cpy_id }).then((res) => {
      //   let ay = [];
      //   if (res.data.data.length > 0) {
      //     res.data.data.forEach((it) => {
      //       ay.push({
      //         value: it.id,
      //         name: it.rName,
      //       });
      //     });
      //   }
      //   formArray[5].data = ay;
      // });
      break;
    case "isadmin":
      user_tid = obj.value;
      console.log("user_tid", user_tid);
      setBindInfo();
      // if (cpy_id > 0) {
      // } else {
      //   ElNotification({
      //     title: t("commKey.MSG_TITLE"),
      //     message: t("commKey.QXZGS"),
      //     duration: 2000,
      //   });
      // }
      // formArray[6].data = obj.data;
      break;
  }
};
const callbackEvent = (obj) => {
  switch (obj.type) {
    case "add":
    case "edit":
      if (obj.row) {
        console.log("edit");
        obj.row.cPwd = obj.row.uPwd;
        if (!Array.isArray(obj.row.uVelIDS) && obj.row.uVelIDS != null) {
          obj.row.uVelIDS = obj.row.uVelIDS.split(",");
        }

        Object.assign(curr_row, obj.row);
        formEvent({
          type: "company",
          value: obj.row.uCompanyID,
          tid: obj.row.uIsAdministrator,
          velids: obj.row.uVelIDS,
        });
      }
      // activeShow.value = true;
      activeKey.value = "GpsForm";
      refAnyCom.value.dialogVisible = true;
      break;
    case "delete":
      proxy.$api.sys_users_delete({ ids: obj.row.id, t: 1 }).then((res) => {
        if (res.data.result == 1) {
          // proxy.$api
          //   .sys_orderdata_save({ ids: obj.row.id, t: 1 })
          //   .then((res_res) => {});
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETESUCCESS"),
            duration: 2000,
          });
        } else {
          ElNotification({
            title: t("commKey.MSG_TITLE"),
            message: t("commKey.DELETEFAIL"),
            duration: 2000,
          });
        }
        getList();
      });
      break;

    case "search":
      Object.assign(query, obj.search);
      getList();
      break;
  }
};
const saveEvent = (temp) => {
  if (parseInt(temp.uCompanyID) == 0) {
    ElMessage({
      type: "error",
      message: t("map.qingxuanzegongsi"),
    });
    return;
  }
  if (temp.uPwd != temp.cPwd) {
    ElMessage({
      type: "error",
      message: t("map.shurudemimabuyizhi"),
    });
    return;
  }

  // if (parseInt(temp.uCompanyID) == 0) {
  //   ElNotification({
  //     title: t("commKey.MSG_TITLE"),
  //     message: t("commKey.MSG_" + res.data.msg_type),
  //     duration: 2000,
  //   });
  //   layer.msg(lang.empty_select_company);
  //   return;
  // }

  // var val_uUserName = $("#uUserName").val();
  // if (val_uUserName == "") {
  //   layer.msg(lang.uUserName + lang.alert_empty);
  //   return;
  // }

  // var val_uPwd = $("#uPwd").val();
  // if (val_uPwd == "") {
  //   layer.msg(lang.uPwd + lang.alert_empty);
  //   return;
  // } else {
  //   if (!check(val_uPwd)) {
  //     layer.msg(lang.userpwd_rule);
  //     return;
  //   }
  // }

  // var val_cPwd = $("#cPwd").val();
  // if (val_cPwd == "") {
  //   layer.msg(lang.cPwd + lang.alert_empty);
  //   return;
  // } else {
  //   if (!check(val_cPwd)) {
  //     layer.msg(lang.userpwd_rule);
  //     return;
  //   }
  // }

  // if (val_uPwd != val_cPwd) {
  //   layer.msg(lang.pwdNOSame);
  //   return;
  // }
  // var val_uRoleID = $("#uRoleID").val();
  // if (parseInt(val_uRoleID) == 0) {
  //   layer.msg(lang.empty_select_role);
  //   return;
  // }
  proxy.$api.sys_users_save(temp).then((res) => {
    ElNotification({
      title: t("commKey.MSG_TITLE"),
      message: t("commKey.MSG_" + res.data.msg_type),
      duration: 2000,
    });
    if (res.data.msg_type == 1 || res.data.msg_type == 2) {
      refAnyCom.value.dialogVisible = false;
      getList();
    }
  });
};

const bindEvent = (obj) => {
  let ay = [];
  switch (obj.type) {
    case "search":
      // formArray[14].data.splice(0);
      proxy.$api
        .sys_get_vehicles_all({
          keyname: obj.veh_cph,
          group_id: obj.group_val,
          zt: obj.veh_zt,
          is_bind: obj.is_bind,
          company_id: obj.cid,
          ids: obj.ids,
        })
        .then((res) => {
          // console.log(res.data.data);
          res.data.data.forEach((it) => {
            it.label = it.vPlate;
          });
          refAnyCom.value.setSearch(res.data.data);
          // searchData.value = res.data.data;
        });
      break;
  }
  // console.log(obj);
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content .flex-row {
  width: 100%;
}
::v-deep .flex-row span {
  display: block;
  padding: 1px 7px;
  border-radius: 5px;
  border: 1px solid #dcdfe6;
  width: 100%;
  cursor: pointer;
}
</style>
