import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  Delete,
  BottomLeft,
  TopRight,
  Search,
  Download,
  Edit,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tableCols: [
    { name: t("users.uCompanyID"), filed: "cName" },
    {
      name: t("users.uIsAdministrator"),
      filed: "uIsAdministrator",
      render: (row) => {
        return t("map.auth_" + row.uIsAdministrator);
      },
    },
    { name: t("users.uRoleID"), filed: "rName" },
    { name: t("users.uUserName"), filed: "uUserName" },
    { name: t("users.uTrueName"), filed: "uTrueName" },
    { name: t("users.uTel"), filed: "uTel" },
    { name: t("users.uEmail"), filed: "uEmail" },
    {
      name: t("users.uIsLogin"),
      filed: "uIsLogin",
      render: (row) => {
        return row.uIsLogin == 1 ? "ON" : "OFF";
      },
    },
    { name: t("users.uAddTime"), filed: "uAddTime" },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Edit),
      name: t("commKey.Edit"),
      type: "",
      click: (row, callback) => {
        api.sys_users_row({ id: row.id }).then((res) => {
          // console.log(res.data.row, "11");
          let _row = res.data.row;
          callback({ type: "edit", row: _row });
        });
      },
    },
    {
      opType: "del",
      icon: markRaw(Delete),
      name: t("commKey.Delete"),
      type: "danger",
      click: (row, callback) => {
        callback({ type: "delete", row: row });
        // activeKey.value = "test";
      },
    },
  ],
  searchCols: [
    {
      type: "ipt",
      title: t("commKey.keynameUser"),
      key: "keyname",
      val: "",
    },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    {
      type: "btn",
      btnType: "primary",
      title: t("commKey.Add"),
      icon: markRaw(Plus),
      click: (callback) => {
        callback({ type: "add", row: { id: 0 } });
      },
    },
  ],
  formCols: [
    {
      type: "sel",
      label: t("users.uCompanyID"),
      field: "uCompanyID",
      placeholder: t("users.uCompanyID"),
      val: "",
      data: [],
      required: true,
      change: (val, item, callback) => {
        callback({ type: "company", value: val });
      },
    },
    {
      type: "ipt",
      label: t("users.uUserName"),
      field: "uUserName",
      placeholder: t("users.uUserName"),
      val: "",
      required: true,
    },
    {
      type: "pwd",
      label: t("users.uPwd"),
      field: "uPwd",
      placeholder: t("users.uPwd"),
      val: "",
      required: true,
    },
    {
      type: "pwd",
      label: t("users.cPwd"),
      field: "cPwd",
      placeholder: t("users.cPwd"),
      val: "",
      required: true,
    },
    {
      type: "sel",
      label: t("users.uIsAdministrator"),
      field: "uIsAdministrator",
      placeholder: t("users.uIsAdministrator"),
      val: "",
      required: true,
      data: [],
      change: (val, item, callback) => {
        callback({ type: "isadmin", value: val });
        // console.log(val);
      },
    },
    {
      type: "sel",
      label: t("users.uRoleID"),
      field: "uRoleID",
      placeholder: t("users.uRoleID"),
      val: "",
      required: true,
      data: [],
    },
    {
      type: "ipt",
      label: t("users.uTrueName"),
      field: "uTrueName",
      placeholder: t("users.uTrueName"),
      val: "",
      required: false,
    },
    {
      type: "ipt",
      label: t("users.uTel"),
      field: "uTel",
      placeholder: t("users.uTel"),
      val: "",
      required: false,
    },
    {
      type: "radio",
      label: t("users.uIsLogin"),
      field: "uIsLogin",
      placeholder: t("users.uIsLogin"),
      val: "1",
      required: false,
      data: [],
    },
    {
      type: "date",
      label: t("users.uExpireTime"),
      field: "uExpireTime",
      placeholder: t("users.uExpireTime"),
      val: moment().format("YYYY-MM-DD"),
      required: false,
    },
    {
      type: "radio",
      label: t("users.uIsExpire"),
      field: "uIsExpire",
      placeholder: t("users.uIsExpire"),
      val: "0",
      required: false,
    },
    {
      type: "ipt",
      label: t("users.uEmail"),
      field: "uEmail",
      placeholder: t("users.uEmail"),
      val: "",
      required: false,
    },
    {
      type: "radio",
      label: t("users.uIsSingleLogin"),
      field: "uIsSingleLogin",
      placeholder: t("users.uIsSingleLogin"),
      val: "0",
      required: false,
    },
    {
      type: "radio",
      label: t("users.uIsEditPwd"),
      field: "uIsEditPwd",
      placeholder: t("users.uIsEditPwd"),
      val: "0",
      required: false,
    },
    {
      type: "empty",
      label: "",
      field: "",
      placeholder: "",
      val: "",
      required: false,
      data: [],
    },
  ],
};
